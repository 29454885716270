import { defineImplementation } from "@qamf/shell-app-sdk";

import use4DAgent from "./define-4DAgent";
import useAuthentication from "./define-authentication";
import useCloudBackend from "./define-cloud-backend";
import useLocalBackend from "./define-local-backend";

export function defineRestClients() {
	defineImplementation("useCloudBackend", useCloudBackend);
	defineImplementation("useLocalBackend", useLocalBackend);
	defineImplementation("use4DAgent", use4DAgent);
}

export function defineAuthentication() {
	defineImplementation("useAuthentication", useAuthentication);
}
