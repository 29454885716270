import { useAppSettings } from "@qamf/shell-app-sdk";
import { createRestClient } from "scarlett";

export default function defineCloudBackend() {
	const baseClient = createRestClient({
		host: "",
		basePath: "/qdesk",
		throw: true,
		credentials: "omit",
		mode: "cors",
		headers: new Headers({
			"Content-Type": "application/json",
			"X-ApiKey": "Tv9741AdiQTwospK5ki0X4NY4x6eV8xvaA"
		}),
		responseType: (_, res) => {
			if (res?.status === 500)
				return "text";

			return "json";
		}
	})();

	const { cloudBackendUrl } = useAppSettings();
	const host = __BACKEND_CLOUD_URL__ || cloudBackendUrl.value;

	if (!host || host.trim() === "")
		throw new Error("[REST BE URL] Missing Backend base Url option.");

	baseClient.setOption("host", host);

	return baseClient;
}
